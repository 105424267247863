import React from "react";
import "./App.css";
import "./components/global/primary.css";
import "./components/global/grid.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorComponent from "./components/Error Component/ErrorComponent";
import AgentSearch from "./pages/AgentSearch";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AgentSearch />} />
          <Route path="*" element={<ErrorComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
