import { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (options) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target); // Stop observing after it becomes visible
      }
    }, options);

    const currentRef = ref.current; // Store the current ref value

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use stored ref
      }
    };
  }, [options]);

  return [ref, isVisible];
};

export default useIntersectionObserver;
