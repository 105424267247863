import React from "react";
import "./partner.css";
import awsIcon from "../../assets/PartnerSection/amazon-icon.png";
import cisco from "../../assets/PartnerSection/cisco-icon.png";
import atTIcon from "../../assets/PartnerSection/at-and-t-icon.png";
import dellIcon from "../../assets/PartnerSection/dell-icon.png";
import comcastIcon from "../../assets/PartnerSection/comcast-icon.png";
import verizonIcon from "../../assets/PartnerSection/verizon-icon.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const PartnerSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`trusted-partners ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="trusted-by-partners-wrapper">
          <h2>Build Trust and Transparency with Industry Leading Partners</h2>
          <div className="row partner-logo-images">
            <img src={awsIcon} alt="Amazon Logo" />
            <img src={cisco} alt="Cisco Logo" />
            <img src={atTIcon} alt="At and T Logo" />
            <img src={dellIcon} alt="Dell Logo" />
            <img src={comcastIcon} alt="Comcast Logo" />
            <img src={verizonIcon} alt="Verizon Logo" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default PartnerSection;
