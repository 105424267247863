import React from "react";
import errorImage from "./error-image.svg";
import "./ErrorComponent.css";
export default function ErrorComponent() {
  return (
    <div className="error-image">
      <img src={errorImage} alt="Error" />
    </div>
  );
}
