import React from "react";
import "./BenefitsSection.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import reduceImage from "../../assets/illustration & Icons/Icons/Reduced Query Time@2x.png";
import OptimizeImage from "../../assets/illustration & Icons/Icons/Optimized Resource Utilization@2x.png";
import improvedImage from "../../assets/illustration & Icons/Icons/Improved Knowledge Management@2x.png";
import enhancedImage from "../../assets/illustration & Icons/Icons/Enhanced data security@2x.png";
import contexualSearch from "../../assets/illustration & Icons/Icons/Contextual Search Relevancy@2x.png";
const BenefitCard = ({ title, description, icon }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 benefits-card">
      <div className="benefits-card-inner">
        <div className="benefits-icon">
          <img src={icon} alt="benefits-icon" />
        </div>
        <div className="benefits-text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
const BenefitsSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`benefits-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>BENEFITS</span>
            </div>
            <div className="heading-description">
              <h2>Key Benefits of AgentSearch</h2>
              <p>
                Discover how AgentSearch enhances enterprise efficiency, data
                accuracy, and resource optimization with Agentic AI
              </p>
            </div>
          </div>
          <div className="benefits-cards-wrapper row">
            <BenefitCard
              title="Reduced Query Time"
              description="Minimizes search time, allowing for faster access to critical data"
              icon={reduceImage}
            />
            <BenefitCard
              title="Contextual Search Relevancy"
              description="Delivers highly relevant results based on advanced understanding of query context"
              icon={contexualSearch}
            />
            <BenefitCard
              title="Optimized Resource Utilization"
              description="Efficiently uses resources, reducing computational overhead while delivering accurate results"
              icon={OptimizeImage}
            />
            <BenefitCard
              title="Improved Knowledge Management"
              description="Centralizes and organizes data, enhancing overall enterprise knowledge accessibility"
              icon={improvedImage}
            />
            <BenefitCard
              title="Enhanced data security"
              description="Ensures that sensitive enterprise data is securely accessed and retrieved, adhering to compliance and privacy standards"
              icon={enhancedImage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
