import React from "react";
import "./roi.css";
import line from "../../assets/ROI/line.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import NumberDisplay from "../NumberDisplay";
export default function ROI() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`roi-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-description">
              <h2>AgentSearch ROI</h2>
            </div>
          </div>
          <div className="roi-content-wrapper">
            <div className="roi-card efficiency">
              <NumberDisplay
                first={21}
                last={65}
                char="%"
                isVisible={isVisible}
              />
              <h3>Efficiency in Knowledge Search</h3>
            </div>
            <img className="divider" src={line} alt="line" />
            <div className="roi-card reduction">
              <NumberDisplay
                first={100}
                last={70}
                char="%"
                isVisible={isVisible}
              />

              <h3>
                Query Time <br /> Reduction
              </h3>
            </div>
            <img className="divider" src={line} alt="line" />

            <div className="roi-card timely-resolution">
              <NumberDisplay
                first={46}
                last={90}
                char="%"
                isVisible={isVisible}
              />
              <h3>Increase in Contextual Relevancy </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
