import React, { useState, useRef, useEffect } from "react";
import "./banner.css";
import "./header.css";
import agentSearchLogo from "../../assets/BannerSection/agent-search-logo.svg";
import arrowIcon from "../../assets/BannerSection/arrow-icon.svg";
import bannerIcon1 from "../../assets/BannerSection/banner-card-1.png";
import bannerIcon2 from "../../assets/BannerSection/banner-card-2.png";
import bannerIcon3 from "../../assets/BannerSection/banner-card-3.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
const Banner = () => {
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const navLinks = useRef([]);
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [ids] = useState({
    Solutions: "one",
    "Use cases": "two",
    Features: "three",
    Benefits: "four",
    FAQ: "five",
  });
  const scrollToSection = (id) => {
    const headerOffset = 100; // Adjust for fixed header height
    const sectionElement = document.getElementById(id);
    const elementPosition = sectionElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const link = navLinks.current.find(
            (link) => link.dataset.target === entry.target.id
          );
          if (entry.isIntersecting) {
            link.classList.add("redirection-active");
          } else {
            link.classList.remove("redirection-active");
          }
        });
      },
      { threshold: [0.4] }
    );

    navLinks.current.forEach((link) => {
      if (!link) return;
      const sectionId = link.dataset.target;
      const sectionElement = document.getElementById(sectionId);
      observer.observe(sectionElement);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target)
    ) {
      setIsMenuActive(false);
    }
  };

  useEffect(() => {
    if (isMenuActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuActive]);

  const handleScroll = () => {
    const diff = window.innerWidth < 1024 ? 20 : 90;
    setIsHeaderActive(window.scrollY > diff);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll(); // To set initial state on mount
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        const mobileHamburger = document.querySelector(".mobile-hamburger");
        const mainMenu = document.querySelector(".main-navigation__menu");

        if (mobileHamburger) {
          mobileHamburger.classList.remove("is-activeHamburger");
        }
        if (mainMenu) {
          mainMenu.classList.remove("main-navigation__menu-active");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={`banner-section ${isVisible ? "animate" : ""}`}
      ref={ref}
    >
      <div className="container">
        {/* Header */}
        <header
          className={`pricing-header header ${isHeaderActive ? "active" : ""}`}
        >
          <div className="container">
            <nav>
              <div className="elixir-logo">
                <img src={agentSearchLogo} alt="agent-search-logo" />
              </div>
              <ul className="headder-content" ref={menuRef}>
                {["Solutions", "Use cases", "Features", "Benefits", "FAQ"].map(
                  (item, index) => (
                    <li
                      key={index}
                      className="elixirdata-hover-element-header"
                      tabIndex="0"
                    >
                      <a
                        href={`#${ids[item]}`}
                        data-target={ids[item]}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection(ids[item]);
                        }}
                        ref={(el) => navLinks.current.push(el)}
                      >
                        <p className="platform">{item}</p>
                      </a>
                    </li>
                  )
                )}
              </ul>
            </nav>
            <div
              className={`mobile-navigation-header ${
                isHeaderActive ? "mobile-active" : ""
              }`}
            >
              <div className="mobile-logo-side">
                <a href="https://agentsearch.ai">
                  <div className="elixir-logo">
                    <img src={agentSearchLogo} alt="agent-search-logo" />
                  </div>
                </a>
              </div>
              <div
                className={`mobile-hamburger ${
                  isMenuActive ? "is-activeHamburger" : ""
                }`}
                onClick={handleHamburgerClick}
                ref={hamburgerRef}
              >
                <div className="hamburger" id="hamburger-6">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
            </div>
            <nav
              className={`main-navigation__menu ${
                isMenuActive ? "main-navigation__menu-active" : ""
              }`}
            >
              <div className="container">
                <ul id="redirection-links" ref={menuRef}>
                  {[
                    "Solutions",
                    "Use cases",
                    "Features",
                    "Benefits",
                    "FAQ",
                  ].map((item, index) => (
                    <li key={index} tabIndex="0">
                      <a
                        href={`#${ids[item]}`}
                        data-target={ids[item]}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection(ids[item]);
                          setIsMenuActive(false);
                        }}
                        ref={(el) => navLinks.current.push(el)}
                      >
                        <p>{item}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        {/* Banner Content */}
        <div className="banner-content-wrapper">
          <h1>Agentic AI-Powered Knowledge Bases for Enterprise Data</h1>
          <p>
            Managing vast amounts of unstructured data is challenging without
            intelligent systems to extract relevant insights.
          </p>
          <div className="banner-button-wrapper">
            <a href={config.bookdemo.Link}>
              <button className="banner-button">
                <p>Book Demo</p>
                <img src={arrowIcon} alt="arrow-icon" />
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="banner-cards-wrapper">
        <div className="banner-card banner-first-card">
          <img src={bannerIcon1} alt="banner-card-icon" />
          <h2>Unstructured Data Management </h2>
          <p>
            Simplify the management of complex, unstructured data by leveraging
            Agentic AI to extract meaningful insights quickly.
          </p>
        </div>
        <div className="banner-card banner-second-card">
          <img src={bannerIcon2} alt="banner-card-icon" />
          <h2>Contextual Relevancy</h2>
          <p>Search results often lack context, causing irrelevant outcomes.</p>
        </div>
        <div className="banner-card banner-third-card">
          <img src={bannerIcon3} alt="banner-card-icon" />
          <h2>Regulatory Compliance</h2>
          <p>
            Data privacy and compliance issues arise without securely handling
            sensitive information.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
